import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const CentreAlignedButton = ({buttonText, onClick, disabled=false}) => {
  const useStyles = makeStyles(theme => ({
    flex: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 20,
      paddingBottom: 20
    }
  }))

  const classes = useStyles()

  return (
    <div className={classes.flex} >
      <Button variant='contained' onClick={onClick} color="primary" disabled={disabled}>
          {buttonText}
      </Button>
    </div>       
  )
}

export default CentreAlignedButton

