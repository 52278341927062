import moment from 'moment'
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export const getWeekDay = date => {
  const today = moment(new Date()).format("YYYY-MM-DD")
  const day = moment(date).format("YYYY-MM-DD") === today ? "Today" : days[date.getDay()]
  return day
}

export const getDateMonth = date => {
  return `${date.getDate()} ${months[date.getMonth()]}`
}

function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01'

    //this can happen when user paste number
  } else if (val > max) {
      val = max
    }
  }

  return val
}

export function monthExpiry(val) {
  const month = limit(val.substring(0, 2), '12')
  const year = val.length >= 2 ? val.substring(2, 6) : ''
  
  return month + '/' + year
}

export const aptTimeString = (value) => {
  // Time of the available appointment, measured in seconds since 00:00:00
  let hour = Math.floor(value / 3600)
  let min = Math.round(value % 3600 / 60)
  const amORpm = hour < 12 ? 'am' : 'pm'
  if (hour > 12) {hour = hour - 12}   
  if (min < 10) {min = "0" + min}
  const timeString = `${hour}:${min} ${amORpm}`
  return timeString
}

const slotToMinutes = (slot) => {
  const indexOfPeriod = slot.indexOf(":")
  const amORpm = slot.substr(-2, 2)
  let hour = parseInt(slot.slice(0, indexOfPeriod))
  if (hour === 12) hour = 0
  if (amORpm === "pm") {hour = hour + 12}
  const minute = slot.slice(indexOfPeriod + 1, indexOfPeriod + 3)
  const result = hour * 60 + parseInt(minute)
  return result
}

export const localDate = (aptDate, slot) => {
  const minutes = slotToMinutes(slot)
  const y = aptDate.slice(0, 4)
  const mon = aptDate.slice(5, 7) - 1 // Jan is 0
  const d = aptDate.slice(8)
  const h = Math.floor(minutes / 60)
  const min = minutes % 60
  return new Date(y, mon, d, h, min)
}

export const localDateTime = (timeStamp) => {
  const time = new Date(timeStamp)
  let hour = time.getHours(time)
  let min = time.getMinutes(time)
  const amORpm = (hour < 12) ? "am" : "pm"
  if (hour > 12) {hour = hour - 12}   
  if (min < 10) {min = "0" + min}
  const timeString = `${hour}:${min} ${amORpm}`

  return `${localDateString(timeStamp)} ${timeString}`
}

export const localDateString = (timeStamp) => {
  const time = new Date(timeStamp)
  let mm = time.getMonth(time) + 1
  if (mm < 10) {mm = "0" + mm}
  let dd = time.getDate(time)
  if (dd < 10) {dd = "0" + dd}
  return `${time.getFullYear(time)}-${mm}-${dd}`
}

export function newDateFromString(bookingTime)
{
  const y = bookingTime.slice(0, 4)
  const mon = bookingTime.slice(5, 7) - 1 // Jan is 0
  const d = bookingTime.slice(8, 10)
  const amORpm = bookingTime.slice(-2)
  let h = parseInt(bookingTime.slice(-8, -6))
  if (amORpm === 'pm' && h < 12)
    h = h + 12
  const min = bookingTime.slice(-5, -3 )

  return new Date(y, mon, d, h, min)
}
