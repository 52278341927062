import React, { useState, useMemo, useEffect } from 'react'
import { navigate } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import Content from '../components/content'
import Paper from '@material-ui/core/Paper' 
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Message from '../components/message'
import CentreAlignedButton from '../components/centered-button'
import { getUser, setUser } from '../components/auth/app-user'
import { API } from 'aws-amplify'
import { createAppointment }  from '../graphql/mutations'
import { addAppointmentToBP } from '../utils/booking-api'
import titleImg from '../images/bg7.jpg'
import { consultationTypes } from "../utils/bp-codes"
import { useAppointmentProfiles } from '../utils/useAppointmentProfiles'
import { useEmailConfirmation } from '../utils/useEmailConfirmation'
import TermsConditions from '../components/terms-and-conditions'
import { google, outlook, office365 } from "calendar-link"
import { aspireMCBookingEmail } from '../utils/booking-api'
import { localDateString, newDateFromString } from '../utils/date-formatter'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
    maxWidth: 600,
    padding: 10,
    [theme.breakpoints.up('sm')]: {
      margin: "auto",
      padding: 30
    }
  },
  radio: {
    marginTop: 10
  }
}))

const PageBook = () => {
  const { allMarkdownRemark } = useAppointmentProfiles()
  let doctorTitles = {}
  let consultationGroups = {}
  let tcs = {}
  let emailTemplateTags = {}
  allMarkdownRemark.edges.forEach(({node}) => {
    doctorTitles[node.frontmatter.bpid] = node.frontmatter.title
    consultationGroups[node.frontmatter.bpid] = node.frontmatter.consultationGroup
    tcs[node.frontmatter.bpid] = node.frontmatter.terms
    emailTemplateTags[node.frontmatter.bpid] = node.frontmatter.emailConfirmation
  })

  let emailTemplates = {}
  const { allMarkdownRemark: emailTemps } = useEmailConfirmation()
  emailTemps.edges.forEach(({node}) => {
    emailTemplates[node.frontmatter.emailConfirmation] = node.html
  })

  const [conTypeIndex, setConTypeIndex] = useState(0)
  const userInfo = getUser()
  const [patientName] = useState(userInfo.patientName)
  const [appId] = useState(userInfo.appId)
  const { patientId, bpPatientId, teleEligible, drId, appTime, appDuration, email } = userInfo
  const [conTypeList, setConTypeList] = useState(consultationTypes.maleGP)
  const [triggerMessage, setTriggerMessage] = useState(false)
  const [stage, setStage] = useState(0)
  const classes = useStyles()
  
  useEffect(() => {
    if (teleEligible) {
      setConTypeList(consultationTypes[consultationGroups[drId]])
    }
    else {
      //Filter out Phone/video consult type if not eligible
      setConTypeList(consultationTypes[consultationGroups[drId]].filter(item => item.code !== 30 && item.code !== 29))
    }
   
  }, [teleEligible])

  const message = useMemo(() => {
    return `You have booked ${patientName} with ${appId} for a ${conTypeList[conTypeIndex].label}. An email has been sent to you for confirmation. If you don't receive it in a few seconds, please check your Spam folder.`
  }, [patientName, appId, conTypeList, conTypeIndex])
  
  const addAppointment = async (username) => {
    const aptTime = new newDateFromString(appTime)
    const aptDate = localDateString(aptTime)

    try {
      const bpAptId = await addAppointmentToBP(appTime.substring(0, 10), appTime.substring(11), appDuration, conTypeList[conTypeIndex].code, drId, bpPatientId)
      console.log("Add appointment return code: ", bpAptId)
      // Someone has taken this slot one step ahead
      if (bpAptId === 0) {
        alert("Sorry, someone just booked an appointment at the same time before you. Please try another appointment time.")
        return 0
      } else if (bpAptId === -1) {
        alert("You only can book one appointment a day.")
        return 0
      } else if (bpAptId === -2) {
        alert("Your online booking is blocked because you have failed to attend appointments 3 times. Please call our reception to resolve this issue.")
        return 0
      } else if (bpAptId === -3) {
        alert("Sorry, the time you chose is not long enough for the appointment type you tried to book. Please try another time.")
        return 0
      } else if (bpAptId === null) {
        alert("The patient you tried to book has been deleted/merged in our system. Delete the patient in your online account and add the updated patient back to your online account to try again.")
        return 0
      }

      await API.graphql({
        query: createAppointment, 
        variables:
          {
            input: {
              id: bpAptId.toString(),
              patientID: patientId,
              bookedBy: username,
              appointmentDate: aptDate,
              appointmentTime: aptTime
            }
          },
          authMode: 'AWS_IAM'
        })

      return bpAptId
      
    } catch (err) {
      console.error('Amplify createAppointment error...: ', err)
      //Email error instead
      fetch(`${process.env.GATSBY_ASPIRE_COMM_SERVER}/send-email`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          emails: ["support@aspiremedicalcentre.com.au"],
          body: `Patient: ${patientId},
          Booked By: ${username},
          Appointment Date: ${aptDate},
          Appointment Time: ${aptTime},
          BP Booked Time: ${appTime},
          Browser: ${navigator.appVersion}`,
          subject: 'Amplify createAppointment error',
          source: aspireMCBookingEmail   
        })
      })
      
      // throw new Error(`${JSON.stringify(err)} Amplify createAppointment error`)
    }
  }

  const ConfirmDetails = () => {
    return (
      <>
        <Paper className={classes.root} elevation={3}>
        <Typography variant="h6" align="left" gutterBottom>
          {`Booking ${patientName} with ${appId} for a`}
        </Typography>
        <FormControl className={classes.radio} component="fieldset">
          <FormLabel component="legend">Consultation type</FormLabel>
          <RadioGroup 
            aria-label="consultation type" 
            name="consultation" 
            value={conTypeIndex} 
            onChange={event => setConTypeIndex(parseInt(event.target.value))}
          >
            {conTypeList.map((item, index) => 
              <FormControlLabel key={index} value={index} control={<Radio />} label={item.label} />)}
          </RadioGroup>
        </FormControl>
        </Paper>
        <CentreAlignedButton buttonText='confirm appointment details' onClick={() => setStage(1)}/>
      </>
    )
  }

  const emailConfirmation = () => {
    const event = {
      title: `Appointment with ${appId}`,
      description: "Be there!",
      start: appTime,
      duration: [appDuration, "m"],
      location: "2 Hillview Rd, Eastwood NSW 2122"
    }

    const outlookLink = outlook(event)
    const googleLink = google(event)
    const office365Link = office365(event)
    
    const calendarLinks = `<p style="font-family: Helvetica, Arial, sans-serif; color: #4bb0c2; font-size: 16px; line-height: 20px; margin: 20px 0 0 0;">
    Click the links below to add the appointment to your calendar
    </p>
    <p style="font-family: Helvetica, Arial, sans-serif; color: #4bb0c2; font-size: 16px; line-height: 20px; margin: 10px 0 0 0;">
      <a style="color: #4bb0c2" href=${outlookLink}>Outlook.com</a>
      &nbsp;|&nbsp;
      <a style="color: #4bb0c2" href=${office365Link}>Office 365</a>
      &nbsp;|&nbsp;
      <a style="color: #4bb0c2" href=${googleLink}>Google</a>
    </p>`

    //send confirmation email
    fetch(`${process.env.GATSBY_ASPIRE_COMM_SERVER}/send-email`, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        emails: [email],
        body: `<p>You have booked ${patientName} with ${appId} for a ${conTypeList[conTypeIndex].label}</p>.
        ${emailTemplates[emailTemplateTags[drId]]}
        ${calendarLinks} <br>
        This is an automatically generated email. Please DO NOT reply to this email.`,
        subject: 'Your booking with Aspire Medicare Centre Eastwood is confirmed!',
        source: aspireMCBookingEmail   
      })
    })
  }

  const book = async () => {
    const userInfo = getUser()
    try {
      const bpAptId = await addAppointment(`${userInfo.given_name} ${userInfo.family_name}`)

      if (bpAptId === 0) {
        navigate('/appointment-browser')
        return
      }

      setTriggerMessage(!triggerMessage)

      emailConfirmation()

      //clear patient and appId
      const updatedUserInfo = getUser()
      delete updatedUserInfo.patientName
      delete updatedUserInfo.patientId
      delete updatedUserInfo.bpPatientId
      delete updatedUserInfo.teleEligible
      delete updatedUserInfo.drId
      delete updatedUserInfo.appId
      updatedUserInfo.checkingBookingStatus = true
      setUser(updatedUserInfo)
    }
    catch (err) {
      alert(`${err} System error. Please contact us by clicking the orange TEXT button on the bottom right corner.`)
    }
  }

  return (
    <Content title='Booking appointment' titleImg={titleImg} tagline="For phone consult, message us to check eligibility and book" >
      {stage === 0 && <ConfirmDetails/>}
      {stage === 1 && <TermsConditions terms={tcs[drId]} book={book}/>}
      <Message 
        triggerOpen={triggerMessage} 
        initOpen={false}
        message={message}
        action="OK"
        cb={() => navigate('/my-account')}
        disableClose={true}
      />        
    </Content>    
  )
}

export default PageBook